import React from 'react';
import {IS_PLATFORM_ANDROID, Panel} from '@vkontakte/vkui';
import Header from '../../components/Header';
import { utils } from '@mini-core/core';

import './index.scss';
import bp from "../../assets/images/bp_logo.png";
import bp_2x from "../../assets/images/bp_logo_2x.png";
import sber from "../../assets/images/sber_logo.png";
import sber2x from "../../assets/images/sber_logo_2x.png";
import mail from "../../assets/images/mailru_logo.png";
import mail2x from "../../assets/images/mailru_logo_2x.png";

const Stream = ({id}) => {
    return (
        <Panel id={id} separator={false}>
            <div className="container">
                <Header showSocial/>
                <div className="stream">
                    <div className="stream__title">
                        Трансляция
                        «Бессмертный полк онлайн»
                    </div>
                    <div
                        onClick={() => utils.fireEvent("https://vk.com/video-99626804_456239682")}
                        className="stream__player"
                        style={{
                            backgroundImage: 'url(https://sun1-95.userapi.com/c858016/v858016650/1effe4/cQZ1S8hZMXI.jpg)'
                        }}
                    >
                        <div className="stream__player-btn">

                        </div>
                    </div>
                    <div className="done-panel__footer"
                         style={
                             {
                                 bottom: IS_PLATFORM_ANDROID ? '20px' : null
                             }
                         }
                    >
                        <div className="done-panel__footer-text">
                            Совместный проект
                        </div>
                        <div className="done-panel__partner done-panel__partner--bp">
                            <img src={bp} srcSet={bp_2x} alt=""/>
                        </div>
                        <div className="done-panel__partner done-panel__partner--sber">
                            <img src={sber} srcSet={sber2x} alt=""/>
                        </div>
                        <div className="done-panel__partner done-panel__partner--mailru">
                            <img src={mail} srcSet={mail2x} alt=""/>
                        </div>
                        <div className="stream__footer-text">
                            Горячая линия "Бессмертного полка" <span style={{textDecoration: 'underline'}}>8&nbsp;(800)&nbsp;20-1945-0</span>
                        </div>
                    </div>
                </div>
            </div>
        </Panel>
    )
};

export default Stream;
