import React from 'react';

import './index.scss'

const ErrorBlock = ({ field_name, text }) => {
    return (
        <div className="error-block">
            {text || `Поле ${field_name} может содержать только русские буквы и символ «-».`}
        </div>
    )
};

export default ErrorBlock;
