import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GA from 'react-ga';

import { Panel } from '@vkontakte/vkui';
import CustomButton from '../../components/CustomButton';
import Header from '../../components/Header';

import './index.scss';

const OnBoarding = ({ id, router }) => {

    useEffect(() => {
        GA.pageview('/on-boarding');
    }, []);

    return (
        <Panel id={id} separator={false}>
            <div className="onboarding">
                <div className="container">
                    <Header/>
                    <div>
                        <h1 className="onboarding__title">
                            Бессмертный полк онлайн
                        </h1>
                        <p className="onboarding__text">
                            Мы бережно храним память о&nbsp;подвиге наших героев. В&nbsp;этом году в&nbsp;связи с&nbsp;пандемией мы предлагаем поддержать акцию «Бессмертный полк» по-новому —&nbsp;онлайн. Расскажите о своём герое и&nbsp;присоединитесь к шествию Бессмертного полка, оставаясь дома.
                        </p>
                        <div className="onboarding__footer">
                            <CustomButton text="ПРИНЯТЬ УЧАСТИЕ" handler={() => router.openPanel("profile", true)}/>
                            <p className="onboarding__text onboarding__text_additional onboarding__text_additional_top">
                                Сбор заявок заканчивается<br/> 9 мая в&nbsp;15:00 <br/>
                                по&nbsp;московскому времени.
                            </p>
                            <p className="onboarding__text onboarding__text_additional onboarding__text_additional_bottom">
                                {/*Старт трансляции 9 мая в&nbsp;02:00 по&nbsp;московскому времени.*/}
                                <a href="https://vk.com/video-99626804_456239682" target="_blank" rel="noopener noreferrer">Смотреть трансляцию</a>
                            </p>
                        </div>
                    </div>
                    <div className="onboarding__bg">

                    </div>
                </div>
            </div>
        </Panel>
    )
};

OnBoarding.propTypes = {
    id: PropTypes.string.isRequired
};

export default OnBoarding;
