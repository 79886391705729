import React, { useRef } from 'react';
import Cropper from 'react-cropper';
import { platform, ANDROID } from '@vkontakte/vkui';
import 'cropperjs/dist/cropper.css'

import './index.scss';
import move from '../../assets/move.svg';
import zoom from '../../assets/zoom.svg';
import dMove from '../../assets/d_move.svg';
import dZoom from '../../assets/d_zoom.svg';
import CustomButton from "../CustomButton";

const CropWindow = ({ imageFile, uploadPhoto, cancel }) => {
    const refCropper = useRef(null);

    const clickCrop = () => {
        refCropper.current.getCroppedCanvas({
            minWidth: 210 * 4,
            minHeight: 280 * 4,
            imageSmoothingEnabled: true,
            imageSmoothingQuality: 'medium',
        }).toBlob(blob => {
            uploadPhoto(blob);
        });
    };

    return (
        <div className="crop-window">
            <div className="crop-window__mask">

            </div>
            <div
                className="crop-window__content"
                style={
                    {
                        padding: platform() === ANDROID ? '20px 0': null,
                    }
                }
            >
                <div className="crop-window__tips">
                    <div className="crop-window__tip crop-window__tip-mobile">
                        <img src={move} width="47" height="40" alt=""/>
                        Перемещайте фото
                    </div>
                    <div className="crop-window__tip crop-window__tip-mobile">
                        <img src={zoom} width="47" height="40"  alt=""/>
                        Увеличивайте или уменьшайте фото
                    </div>
                    <div className="crop-window__tip crop-window__tip-desktop">
                        <img src={dMove} width="47" height="40" alt=""/>
                        Перемещайте фото, зажав левую кнопку мыши
                    </div>
                    <div className="crop-window__tip crop-window__tip-desktop">
                        <img src={dZoom} width="47" height="40"  alt=""/>
                        Увеличивайте или уменьшайте фото с помощью скрола
                    </div>
                </div>
                <div className="crop-window__body">
                    <Cropper
                        ref={ refCropper }
                        src={ imageFile }
                        style={{ height: '100%', width: '100%' }}
                        aspectRatio={ 3 / 4 }
                        cropBoxMovable={ false }
                        cropBoxResizable={ false }
                        dragMode='move'
                        guides={ false }
                        center={ false }
                        toggleDragModeOnDblclick={ false }
                        viewMode={ 3 }
                        checkOrientation={ true }
                        autoCrop={ true }
                        autoCropArea={ 1 }
                    />
                </div>
                <div className="crop-window__text">
                    На фото должен присутствовать только ваш предок, присутствие других людей вместе с&nbsp;ним не&nbsp;допускается, фото не должно быть расположено боком или вверх ногами, необходимо использовать фотографии без обрамлений и подписей (ФИО, даты и др.)
                </div>
                <div className="crop-window__controls">
                    <div className="crop-window__control" onClick={cancel}>
                        Отменить
                    </div>
                    <CustomButton text="Сохранить" handler={clickCrop}/>
                </div>
            </div>
        </div>
    )
};

export default CropWindow;
