import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GA from 'react-ga';
import * as API from '../../networking';

import { Panel } from '@vkontakte/vkui';
import Header from '../../components/Header';
import Form from '../../components/Form';

import './index.scss';

const Profile = ({ id, setProfile, router }) => {
    const [ myProfile, setMyProfile ] = useState({});

    useEffect(() => {
        API.getVKUserProfile()
            .then((user) => {
                if (user !== null) {
                    setMyProfile(user);
                }
            })
    }, []);

    useEffect(() => {
        GA.pageview('/profile');
    }, []);

    return (
        <Panel id={id} separator={false}>
            <div className="profile">
                <div className="container">
                    <Header/>
                    <h2 className="profile__title">
                        {myProfile.first_name}, расскажите о&nbsp;своём герое
                    </h2>
                    <p className="profile__text">
                        Заполните эту анкету, и 9 мая 2020 года профиль вашего героя примет участие в&nbsp;шествии Бессмертного полка онлайн.
                    </p>
                </div>
                <Form
                    router={router}
                    onSubmit={(profile) => {
                        setProfile(profile);
                        router.openPanel('done', true);
                    }}
                    onFail={(title, description) => router.showAlert(title, description)}
                />
            </div>
        </Panel>
    )
};

Profile.propTypes = {
    id: PropTypes.string.isRequired
};

export default Profile;
