import React, { useState } from 'react';
import { router } from '@mini-core/core';

import OnBoarding from '../OnBoarding';
import Profile from '../Profile';
import Done from '../Done';
import Stream from '../Stream';

import './index.scss';

const App = () => {
    const [ profile, setProfile ] = useState({
        first_name: 'Константин',
        middle_name: 'Константинович',
        last_name: 'Константинов',
        birth_date: '',
        death_date: '',
        is_alive: true,
    });

    return (
        <router.Router initialPage="main">
            <router.Page id="main" initialPanel="stream" header={false}>
                <OnBoarding id="on-boarding"/>
                <Profile id="profile" setProfile={setProfile} />
                <Done id="done" profile={profile} />
                <Stream id="stream"/>
            </router.Page>
        </router.Router>
    )
};

export default App;

