import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import bridge from '@vkontakte/vk-bridge';
import { share, utils } from '@mini-core/core';
import GA from 'react-ga';
import * as API from '../../networking';

import { Panel } from '@vkontakte/vkui';
import {ANDROID, platform} from '@vkontakte/vkui';
import Header from '../../components/Header';
import CustomButton from '../../components/CustomButton';

import './index.scss';
import check_mark from '../../assets/check_mark.svg';
import bp from '../../assets/images/bp_logo.png';
import bp_2x from '../../assets/images/bp_logo_2x.png';
import sber from '../../assets/images/sber_logo.png';
import sber2x from '../../assets/images/sber_logo_2x.png';
import mail from '../../assets/images/mailru_logo.png';
import mail2x from '../../assets/images/mailru_logo_2x.png';

const Done = ({ id, profile, router }) => {
    const [ show, setShow ] = useState(true);

    const generateAvatar = async (url, width, height) => {
        const avatar = new share.CanvasBuilder(width, height);
        const photo = await utils.loadFile(url);
        await avatar.addImage(photo, 0, 0, 0, 0, true, ({ cw, ch, iw, ih }) => Math.max(cw / iw, ch / ih));
        return avatar.render();
    };

    const generateStory = async () => {
        const photo = await generateAvatar(profile.photo_link, 210 * 2, 280 * 2);
        const avatar = await generateAvatar(await bridge.send('VKWebAppGetUserInfo', {}).then((x) => x.photo_200), 210 * 2, 280 * 2);

        const story = new share.CanvasBuilder(1080, 1920);
        await story.addImage('/assets/story.png');
        await story.addImage(photo, 60 * 2, 276 * 2);
        await story.addImage(avatar, 270 * 2, 276 * 2);
        await story.addImage('/assets/ribbon.png', 222 * 2, 534 * 2);

        story.addArc(40 * 2 + 40, 256 * 2 + 40, 40, '#F2F2F2', 1, '#F2F2F2');
        story.addArc(460 * 2 + 40, 256 * 2 + 40, 40, '#F2F2F2', 1, '#F2F2F2');
        story.addArc(40 * 2 + 40, 536 * 2 + 40, 40, '#F2F2F2', 1, '#F2F2F2');
        story.addArc(460 * 2 + 40, 536 * 2 + 40, 40, '#F2F2F2', 1, '#F2F2F2');

        story
            .addText(profile.last_name.toUpperCase(), 165 * 2, 645 * 2 + 24, '48px Roboto Bold', '#C62B34', 'center')
            .addText(profile.first_name, 165 * 2, 676 * 2 + 24 + 6, '48px Roboto', '#333333', 'center')
            .addText(profile.middle_name, 165 * 2, 676 * 2 + 48 + 48, '48px Roboto', '#333333', 'center');
        if (profile.birth_date !== '' && profile.death_date !== '') {
            story
                .addText(profile.birth_date + ' –', 165 * 2, 744 * 2 + 48, '42px Roboto', '#7F7F7F', 'center')
                .addText(profile.death_date, 165 * 2, 744 * 2 + 48 + 48, '42px Roboto', '#7F7F7F', 'center');
        } else if (profile.birth_date !== '' && profile.is_alive) {
            story
                .addText(profile.birth_date + ' –', 165 * 2, 744 * 2 + 48, '42px Roboto', '#7F7F7F', 'center')
                .addText('настоящее время', 165 * 2, 744 * 2 + 48 + 48, '42px Roboto', '#7F7F7F', 'center');
        } else if (profile.birth_date !== '') {
            story
                .addText(profile.birth_date + ' –', 165 * 2, 744 * 2 + 48, '42px Roboto', '#7F7F7F', 'center')
                .addText('неизвестно', 165 * 2, 744 * 2 + 48 + 48, '42px Roboto', '#7F7F7F', 'center');
        } else if (profile.death_date !== '') {
            story
                .addText('неизвестно –', 165 * 2, 744 * 2 + 48, '42px Roboto', '#7F7F7F', 'center')
                .addText(profile.death_date, 165 * 2, 744 * 2 + 48 + 48, '42px Roboto', '#7F7F7F', 'center');
        }

        story
            .render(async (blob) => {
                router.hideLoader();
                const scale = 4;
                const render = new share.CanvasBuilder(1080 / scale, 1920 / scale);
                await render.addImage(blob, 0,0, 1080 / scale, 1920 / scale);
                render.preview();

                bridge.send('VKWebAppShowStoryBox', {
                    background_type: 'image',
                    blob,
                    locked: true,
                    attachment: {
                        text: 'open',
                        type: 'url',
                        url: `https://vk.com/app${window.vk_settings.vk_app_id}`
                    }
                });

                GA.event({
                    category: 'Sharing',
                    action: 'story',
                });
            });
    };

    const generatePost = async () => {
        const photo = await generateAvatar(profile.photo_link, 270, 360);
        const avatar = await generateAvatar(await bridge.send('VKWebAppGetUserInfo', {}).then((x) => x.photo_200), 270, 360);

        const post = new share.CanvasBuilder(1120, 630);
        await post.addImage('/assets/post.png');
        await post.addImage(photo, 520, 110 - 30);
        await post.addImage(avatar, 790, 110 - 30);
        await post.addImage('/assets/ribbon_desktop.png', 720, 437 - 30);

        post.addArc(496 + 24, 86 + 24 - 30, 48, '#F2F2F2', 1, '#F2F2F2');
        post.addArc(1036 + 24, 86 + 24 - 30, 48, '#F2F2F2', 1, '#F2F2F2');
        post.addArc(496 + 24, 446 + 24 - 30, 48, '#F2F2F2', 1, '#F2F2F2');
        post.addArc(1036 + 24, 446 + 24 - 30, 48, '#F2F2F2', 1, '#F2F2F2');

        post
            .addText(profile.last_name.toUpperCase(), 245, 284 + 36 - 30, '36px Roboto Bold', '#C62B34', 'center')
            .addText(profile.first_name, 245, 330 + 36 - 30, '36px Roboto', '#333333', 'center')
            .addText(profile.middle_name, 245, 330 + 36 + 36 + 5 - 30, '36px Roboto', '#333333', 'center')

        if (profile.birth_date !== '' && profile.death_date !== '') {
            post.addText(`${profile.birth_date} – ${profile.death_date}`, 245, 448 + 24 - 30, '24px Roboto', '#7F7F7F', 'center');
        } else if (profile.birth_date !== '' && profile.is_alive) {
            post.addText(`${profile.birth_date} – настоящее время`, 245, 448 + 24 - 30, '24px Roboto', '#7F7F7F', 'center');
        } else if (profile.birth_date !== '') {
            post.addText(`${profile.birth_date} - неизвестно`, 245, 448 + 24 - 30, '24px Roboto', '#7F7F7F', 'center');
        } else if (profile.death_date !== '') {
            post.addText(`неизвестно - ${profile.death_date}`, 245, 448 + 24 - 30, '24px Roboto', '#7F7F7F', 'center');
        }

        post
            .preview()
            .render(async (post) => {
                const result = await API.updateShareImage(profile.profile_id, post);
                if (result !== null) {
                    bridge.send('VKWebAppShowWallPostBox', {
                        message: '',
                        attachments: `https://vk.com/polk_app?p=${profile.profile_id}`,
                        link_button: 'open_url',
                        link_image: result.link,
                        link_title: 'Бессмертный полк онлайн'
                    })

                    GA.event({
                        category: 'Sharing',
                        action: 'post',
                    });
                }
                router.hideLoader();
            });
    };

    useEffect(() => {
        API.getSettings()
            .then((config) => {
                setShow(config.hide_sharing);
            });
    }, []);

    useEffect(() => {
        GA.pageview('/done');
    }, []);

    return (
        <Panel id={id}>
            <div style={{ fontFamily: 'Roboto Bold', position: 'absolute', top: -100000, left: -100000 }}>.</div>
            <div style={{ fontFamily: 'SignPainter', position: 'absolute', top: -100000, left: -100000 }}>.</div>
            <div className="container">
                <Header/>
                <div className="done-panel">
                    <div className="done-panel__title">
                        <div className="done-panel__icon">
                            <img src={check_mark} alt=""/>
                        </div>
                        Заявка принята
                    </div>
                    {profile.photo_link !== '' && (
                        <div className="done-panel__text">
                            Расскажите друзьям о своём герое
                        </div>
                    )}
                    <div className="done-panel__controls">
                        {profile.photo_link !== '' && (
                            <>
                                <CustomButton
                                    text="Поделиться в записи"
                                    handler={() => {
                                        router.showLoader();
                                        setTimeout(generatePost, 100);
                                    }}
                                />
                                <CustomButton
                                    text="Создать историю"
                                    handler={() => {
                                        router.showLoader();
                                        setTimeout(generateStory, 100);
                                    }}
                                />
                            </>
                        )}
                        <div className="done-panel__control" onClick={() => router.openPanel('profile', true)}>
                            Новая заявка
                        </div>
                    </div>
                    <div className="done-panel__footer"
                         style={
                             {
                                 bottom: platform() === ANDROID ? '20px' : null
                             }
                         }
                    >
                        <div className="done-panel__footer-text">
                            Совместный проект
                        </div>
                        <div className="done-panel__partner done-panel__partner--bp">
                            <img src={bp} srcSet={bp_2x} alt=""/>
                        </div>
                        <div className="done-panel__partner done-panel__partner--sber">
                            <img src={sber} srcSet={sber2x} alt=""/>
                        </div>
                        <div className="done-panel__partner done-panel__partner--mailru">
                            <img src={mail} srcSet={mail2x} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </Panel>
    )
};

Done.propTypes = {
    id: PropTypes.string.isRequired
};

export default Done;
