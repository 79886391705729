import React from 'react';
import {ANDROID, platform} from '@vkontakte/vkui';

import './index.scss';
import mobile_logo from '../../assets/images/mobile_logo.png';
import mobile_logo2x from '../../assets/images/mobile_logo_2x.png';

const Header = () => {
    return (
        <div className="header"
             style={
                 {
                     paddingTop: platform() === ANDROID ? '20px' : null
                 }
             }
        >
            <img className="header__logo" src={mobile_logo} srcSet={mobile_logo2x} alt=""/>
        </div>
    )
};

export default Header;
