import React, { useState, useEffect, useCallback } from 'react';
import CustomButton from '../CustomButton';
import * as API from '../../networking';
import FormLoader from '../FormLoader';
import ErrorBlock from '../ErrorBlock';
import CropWindow from '../CropWindow';
import GA from 'react-ga';

import './index.scss';
import plus from '../../assets/plus-icon.svg';

const Form = ({ router, onSubmit, onFail }) => {
    const [ profileId, setProfileId ] = useState(0);
    const [ errors, setErrors ] = useState([]);
    const [ loaderText, setLoaderText ] = useState('');
    const [ emailLoaded, setEmailLoaded ] = useState(false);
    const [ showLoader, setShowLoader ] = useState(false);
    const [ state, setState ] = useState({
        first_name: '',
        last_name: '',
        middle_name: '',
        photo_link: '',
        birth_date: '',
        death_date: '',
        participation_years: [],
        is_alive: false,
        author_email: '',
        accept_rules: false,
    });
    const [ imageFile, setImageFile ] = useState(null);
    const [ crop, setCrop ] = useState(false);

    const getEmail = useCallback(async () => {
        if (!emailLoaded) {
            setValue('author_email', await API.getVKEmail());
            setEmailLoaded(true);
        }
    }, [ emailLoaded ]);

    const fieldHandler = (e, field, is_box) => {
        if (is_box) {
            return setValue(field, !state[field]);
        }

        const value = e.currentTarget.value;
        setValue(field, value);
    };

    const setValue = (field, value) => {
        if (errors.includes(field)) {
            setErrors((prev) => prev.filter((x) => x !== field));
        }

        if ([ 'first_name', 'last_name', 'middle_name' ].includes(field)) {
            return setState((prev) => ({
                ...prev,
                [field]: value.trim(),
            }));
        }

        setState((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    const addParticipationYears = (year) => {
        setErrors((prev) => prev.filter((x) => x !== 'participation_years'));
        setState((prev) => ({
            ...prev,
            participation_years: [ ...state['participation_years'].filter((x) => x !== year), year ].sort(),
        }));
    };

    const removeParticipationYears = (year) => {
        setErrors((prev) => prev.filter((x) => x !== 'participation_years'));
        setState((prev) => ({
            ...prev,
            participation_years: state['participation_years'].filter((x) => x !== year),
        }));
    };

    const toBase64 = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const preUploadPhoto = async (file) => {
        const base64 = await toBase64(file);

        setImageFile(base64);
        setCrop(true);
    };

    const deletePhoto = () => {
        API.deletePhoto(profileId);

        setValue('photo_link', '');
    };

    const uploadPhoto = (file) => {
        setLoaderText("Загружаем фотографию");
        setShowLoader(true);
        API.uploadFile(profileId, file)
            .then((response) => {
                setCrop(false);
                setShowLoader(false);
                setValue("photo_link", response.link);
            })
            .catch(() => {
                setCrop(false);
                setShowLoader(false);
            });
    };

    const cancel = () => {
        setCrop(false);
    };

    const submit = () => {
        setLoaderText("Отправка заявки");
        setShowLoader(true);
        let errors = [];

        const hiddenFields = [ 'accept_rules' ];

        // check for error
        Object.keys(state).filter((x) => ![ ...hiddenFields, 'is_alive', 'middle_name', 'death_date', 'birth_date', 'photo_link' ].includes(x)).forEach((x) => {
            if (x === 'participation_years') {
                if (state[x].length < 1) {
                    errors = [ ...errors, x ];
                }
            } else if ([ 'first_name', 'last_name' ].includes(x)) {
                if (state[x].length < 1) {
                    errors = [ ...errors, x ];
                } else {
                    const re = /[^аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ-]+/u;
                    if (re.test(state[x])) {
                        errors = [ ...errors, x ];
                    }
                }
            } else if (x === 'author_email') {
                const re = /\S+@\S+\.\S+/;
                if (!re.test(state.author_email)) {
                    errors = [ ...errors, x ];
                }
            } else if (!!!state[x]) {
                errors = [ ...errors, x ];
            }
        });

        if (state.death_date !== '') {
            const date = /(^([0-2][0-9]|(3)[0-1])([.])(((0)[0-9])|((1)[0-2]))([.])\d{4})|(^(((0)[0-9])|((1)[0-2]))([.])\d{4})|(^\d{4})$/i;

            if (!state.is_alive && !state.death_date) {
                errors = [ ...errors, 'death_date' ];
            }

            if (!state.is_alive) {
                let n = 0;
                if (state.death_date.length >= 4) {
                    n = Number(state.death_date.slice(state.death_date.length - 4, state.death_date.length));
                }

                if (!date.test(state.death_date) || n < 1941 || n > 2020) {
                    errors = [ ...errors, 'death_date' ];
                }
            }
        }

        if (state.birth_date !== '') {
            let n = 0;
            if (state.birth_date.length >= 4) {
                n = Number(state.birth_date.slice(state.birth_date.length - 4, state.birth_date.length));
            }

            const date = /(^([0-2][0-9]|(3)[0-1])([.])(((0)[0-9])|((1)[0-2]))([.])\d{4})|(^(((0)[0-9])|((1)[0-2]))([.])\d{4})|(^\d{4})$/i;
            if (!date.test(state.birth_date) || n < 1840 || n > 1945) {
                errors = [...errors, 'birth_date'];
            }
        }

        if (state.middle_name !== '') {
            const re = /[^аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ-]+/u;
            if (re.test(state.middle_name)) {
                errors = [ ...errors, 'middle_name' ];
            }
        }

        if (errors.length > 0) {
            setErrors(errors);
            window.scrollTo(0, 0);
            return setShowLoader(false);
        }

        // get form
        const data = Object.keys(state).filter((x) => !hiddenFields.includes(x)).reduce((a, x) => {
            if (x === 'participation_years') {
                a[x] = state.participation_years.join(',');
            } else {
                a[x] = state[x];
            }
            return a;
        }, {});

        const allOk = () => {
            setShowLoader(false);
            onSubmit({ ...state, profile_id: profileId });
        };

        API.sendCard({ ...data, profile_id: profileId })
            .then(() => allOk())
            .catch((error) => {
                setShowLoader(false);
                errorHandler(error, allOk);
            })
    };

    const errorHandler = (e, allOk) => {
        const fields = {
            first_name: 'Имя',
            last_name: 'Фамилия',
            middle_name: 'Отчество',
            photo_link: 'Фотография',
            birth_date: 'Дата рождения',
            death_date: 'Дата смерти',
            participation_years: 'Годы участия в войне',
            is_alive: 'Дата смерти',
            author_email: 'Ваша почта',
        };


        switch (e.error) {
            case 'photo_does_not_exist': {
                return onFail("Ошибка", "Попробуйте загрузить фото еще раз!");
            }

            case 'send_card_connection_error': {
                return allOk();
            }

            case 'send_card_error': {
                return onFail("Ошибка", e.error_text || "Попробуйте еще раз");
            }

            case 'filthy_word_fond': {
                return onFail("Ошибка", "Проверьте ФИО на корректность");
            }

            case 'validate_fail': {
                return onFail("Ошибка", `Ошибка в поле "${fields[e.param]}"`);
            }

            case 'profile_already_sent': {
                return allOk();
            }

            default: {
                return onFail("Что-то пошло не так", "Не удалось отправить анкету, попробуйте ещё раз");
            }
        }
    };

    useEffect(() => {
        router.showLoader();
        API.createProfile()
            .then(({ profile_id }) => {
                router.hideLoader();
                setProfileId(profile_id);
            })
            .catch(() => {
                router.hideLoader();
                router.showAlert("Ошибка", "Что-то пошло не так!");
                GA.event({
                    category: 'Profile',
                    action: 'Can not create'
                });
            })
    }, []);

    return (
        <div className="scroll-wrapper">
            <div className="form">
                <div className="form__group">
                    <label htmlFor="surname" className="form__label">
                        Как зовут вашего героя?
                    </label>
                    <input
                        type="text"
                        className={errors.indexOf('last_name') !== -1 ? "form__input form__input--error" : "form__input"}
                        placeholder="Фамилия*"
                        value={state.last_name}
                        onChange={(e) => fieldHandler(e, "last_name")}
                    />
                    {errors.indexOf('last_name') !== -1 &&
                    <ErrorBlock field_name="Фамилия"/>
                    }
                    <input
                        type="text"
                        className={errors.indexOf('first_name') !== -1 ? "form__input form__input--error" : "form__input"}
                        placeholder="Имя*"
                        value={state.first_name}
                        onChange={(e) => fieldHandler(e, "first_name")}
                    />
                    {errors.indexOf('first_name') !== -1 &&
                    <ErrorBlock field_name="Имя"/>
                    }
                    <input
                        type="text"
                        className={errors.indexOf('middle_name') !== -1 ? "form__input form__input--error" : "form__input"}
                        placeholder="Отчество"
                        value={state.middle_name}
                        onChange={(e) => fieldHandler(e, "middle_name")}
                    />
                    {errors.indexOf('middle_name') !== -1 &&
                    <ErrorBlock field_name="Отчество"/>
                    }
                </div>
                <div className="form__group">
                    {!state.photo_link ?
                        <div>
                            <div className="form__label form__label--photo">Фотография</div>
                            <div className="form__tip form__tip--photo">
                                Если у вас нет фотографии ветерана, то&nbsp;оставьте поле под&nbsp;фотографию пустым
                            </div>
                            <label className="form__input--file" htmlFor="file">
                                <img src={plus} alt="Добавить"/>
                                <div>Нажмите, чтобы загрузить фотографию</div>
                                <span>Максимальный размер фото: 5 МБ</span>
                            </label>
                            <input
                                id="file"
                                type="file"
                                onChange={(e) => {
                                    preUploadPhoto(e.target.files[0]);
                                }}
                            />
                        </div> :
                        <div>
                            <div className="form__label">Загрузите фото вашего героя*</div>
                            <label className="form__input--file" htmlFor="file">
                                <div className="form__img-file" style={{ backgroundImage: `url(${state.photo_link})` }} />
                            </label>
                            <input
                                id="file"
                                type="file"
                                onChange={(e) => {
                                    preUploadPhoto(e.target.files[0]);
                                }}
                            />
                        </div>
                    }
                    {state.photo_link && (
                        <div
                            className="form__tip form__tip--delete"
                            style={{ cursor: 'pointer' }}
                            onClick={deletePhoto}
                        >
                            Удалить фото
                        </div>
                    )}
                </div>
                <div className="form__group">
                    <label htmlFor="birthDate" className="form__label">Дата рождения</label>
                    <input
                        type="text"
                        className={errors.indexOf('birth_date') !== -1  ? "form__input form__input--error" : "form__input"}
                        placeholder="ДД.ММ.ГГГГ, ММ.ГГГГ или ГГГГ"
                        value={state.birth_date}
                        onChange={(e) => fieldHandler(e, "birth_date")}
                    />
                    {errors.indexOf('birth_date') !== -1 &&
                    <ErrorBlock text="Поле может содержать дату только в трех видах: ДД.ММ.ГГГГ, ММ.ГГГГ и ГГГГ" />
                    }
                </div>
                <div className="form__group">
                    <label htmlFor="deathDate" className="form__label">Дата смерти</label>
                    <input
                        disabled={state.is_alive}
                        type="text"
                        className={errors.indexOf('death_date') !== -1 ? "form__input form__input--death-date form__input--error" : "form__input form__input--death-date"}
                        placeholder="ДД.ММ.ГГГГ, ММ.ГГГГ или ГГГГ"
                        value={state.death_date}
                        onChange={(e) => fieldHandler(e, "death_date")}
                    />
                    {errors.indexOf('death_date') !== -1 &&
                    <ErrorBlock text="Поле может содержать дату только в трех видах: ДД.ММ.ГГГГ, ММ.ГГГГ и ГГГГ" />
                    }
                    <div className="form__group--checkbox">
                        <input
                            id="isAlive"
                            type="checkbox"
                            className="form__checkbox"
                            value={state.is_alive}
                            checked={state.is_alive}
                            onChange={(e) => {
                                setValue("death_date", "");
                                fieldHandler(e, "is_alive", true);
                            }}
                        />
                        <span className="form__checkbox-default">
                    </span>
                        <label className="form__label form__label--checkbox" htmlFor="isAlive">Мой герой жив</label>
                    </div>
                </div>
                <div className="form__group form__group--years">
                    <div className="form__label">Годы участия в войне*</div>
                    {errors.indexOf('participation_years') !== -1 && (
                        <span className="form__error-message">Выберите даты участия героя в войне</span>
                    )}
                    <div className="form__controls form__controls--sm">
                        <div className="form__control-checkbox">
                            <input
                                id="1941"
                                className="form__checkbox"
                                type="checkbox"
                                name="participationYears"
                                onClick={() => {
                                    const year = '1941';
                                    if (state.participation_years.indexOf(year) === -1) {
                                        addParticipationYears(year);
                                    } else {
                                        removeParticipationYears(year);
                                    }
                                }}
                            />
                            <label className="form__checkbox-custom" htmlFor="1941">1941</label>
                        </div>
                        <div className="form__control-checkbox">
                            <input
                                id="1942"
                                className="form__checkbox"
                                type="checkbox"
                                name="participationYears"
                                onClick={() => {
                                    const year = '1942';
                                    if (state.participation_years.indexOf(year) === -1) {
                                        addParticipationYears(year);
                                    } else {
                                        removeParticipationYears(year);
                                    }
                                }}
                            />
                            <label className="form__checkbox-custom" htmlFor="1942">1942</label>
                        </div>
                        <div className="form__control-checkbox">
                            <input
                                id="1943"
                                className="form__checkbox"
                                type="checkbox"
                                name="participationYears"
                                onClick={() => {
                                    const year = '1943';
                                    if (state.participation_years.indexOf(year) === -1) {
                                        addParticipationYears(year);
                                    } else {
                                        removeParticipationYears(year);
                                    }
                                }}
                            />
                            <label className="form__checkbox-custom" htmlFor="1943">1943</label>
                        </div>
                        <div className="form__control-checkbox">
                            <input
                                id="1944"
                                className="form__checkbox"
                                type="checkbox"
                                name="participationYears"
                                onClick={() => {
                                    const year = '1944';
                                    if (state.participation_years.indexOf(year) === -1) {
                                        addParticipationYears(year);
                                    } else {
                                        removeParticipationYears(year);
                                    }
                                }}
                            />
                            <label className="form__checkbox-custom" htmlFor="1944">1944</label>
                        </div>
                        <div className="form__control-checkbox">
                            <input
                                id="1945"
                                className="form__checkbox"
                                type="checkbox"
                                name="participationYears"
                                onClick={() => {
                                    const year = '1945';
                                    if (state.participation_years.indexOf(year) === -1) {
                                        addParticipationYears(year);
                                    } else {
                                        removeParticipationYears(year);
                                    }
                                }}
                            />
                            <label className="form__checkbox-custom" htmlFor="1945">1945</label>
                        </div>
                    </div>
                </div>
                <div className="form__group form__group--email">
                    <div className="form__label form__label--email">Введите email*</div>
                    <div className="form__subtext">На этот адрес вам придёт письмо, где будет
                        указано время появления вашего героя в&nbsp;Бессмертном полку</div>
                    <input
                        type="email"
                        className={errors.indexOf('author_email') !== -1 ? "form__input form__input--error" : "form__input"}
                        placeholder="email@gmail.com"
                        value={state.author_email}
                        onChange={(e) => fieldHandler(e, "author_email")}
                        onClick={getEmail}
                    />
                    {errors.indexOf('author_email') !== -1 &&
                    <ErrorBlock text="Поле должно содержать правильный email: user@domain.zone" />
                    }
                </div>
                <div className="form__group-footer">
                    <div className="form__group-row">
                        <input
                            id="personalInfo"
                            className="form__checkbox"
                            type="checkbox"
                            onClick={(e) => fieldHandler(e, "accept_rules", true)}
                        />
                        <span className="form__checkbox-default">
                    </span>
                        <label className="form__label form__label--checkbox" htmlFor="personalInfo">
                            Принимаю условия<a href="/assets/ua.pdf" target="_blank" rel="noopener noreferrer"> пользовательского соглашения</a>
                        </label>
                    </div>
                </div>
                <CustomButton disabled={!state.accept_rules} text="Отправить заявку" handler={submit} />
            </div>
            {showLoader && <FormLoader text={loaderText} />}
            {crop && <CropWindow imageFile={ imageFile } uploadPhoto={ uploadPhoto } cancel={ cancel }/>}
        </div>
    )
};

export default Form;
