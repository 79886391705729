import React from 'react';
import {Spinner} from '@vkontakte/vkui';

import './index.scss';

const FormLoader = ({ text }) => {
    return (
        <div className="loader">
            <div className="loader__wrapper">
                <div className="loader__content">
                    <div className="loader__text">
                        {text}
                    </div>
                    <Spinner size="large"/>
                </div>
            </div>
        </div>
    )
};

export default FormLoader;
