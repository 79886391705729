import React from 'react';
import PropTypes from 'prop-types';

import './index.scss'

const CustomButton = ({text, handler, disabled }) => {
    return (
        <div className={disabled ? 'custom-button custom-button--disabled' : 'custom-button'}
             onClick={() => {
                 !disabled && handler()
             }}
        >
            {text}
        </div>
    )
};

CustomButton.defaultProps = {
    disabled: false,
};

CustomButton.propTypes = {
    text: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired
};

export default CustomButton;
