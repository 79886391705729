import bridge from '@vkontakte/vk-bridge';
import { share } from '@mini-core/core';

const ENDPOINT = 'https://back.9may.juice.vk-apps.com'; //back.9may.juice.vk-apps.com

const __getURL = () => {
    return window.location.href;
};

export const getSettings = () => new Promise((resolve) => {
    fetch("https://bp.vkapps.pro/settings.json")
        .then((res) => res.json())
        .then((res) => resolve(res))
        .catch(() => resolve({}));
});

export const getVKUserProfile = () => new Promise((resolve) => {
    if (!(window.vk_settings && window.vk_settings.vk_app_id)) {
        return resolve({
            "id": 2314852,
            "first_name": "Ирина",
            "last_name": "Денежкина",
            "sex": 1,
            "city": {
                "id": 2,
                "title": "Санкт-Петербург"
            },
            "country": {
                "id": 1,
                "title": "Россия"
            },
            "bdate": "10.4.1990",
            "photo_100": "https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg",
            "photo_200": "https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg",
            "timezone": 3
        });
    }

    bridge.send('VKWebAppGetUserInfo', {})
        .then((response) => resolve(response))
        .catch(() => resolve(null))
});

export const sendCard = (data) => new Promise((resolve, reject) => {
    const form = new FormData();
    form.append('url', __getURL());
    Object.keys(data).forEach((x) => {
        form.append(x, String(data[x]));
    })

    fetch(ENDPOINT + '/send-card', {
        method: 'POST',
        body: form,
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.result === "success") {
                resolve();
            } else {
                reject(response);
            }
        })
        .catch((e) => {
            console.error(e);
            reject({});
        });
})

export const uploadFile = (profile_id, file) => new Promise((resolve, reject) => {
    const data = new FormData();
    data.append('profile_id', String(profile_id));
    data.append('photo', file, file.name);
    data.append('url', __getURL());

    fetch(ENDPOINT + '/upload-photo', {
        method: 'POST',
        body: data,
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.result === "success") {
                resolve({
                    link: response.link,
                });
            } else {
                console.log(response);
                reject(null);
            }
        })
        .catch((e) => {
            console.error(e);
            reject(null);
        });
});

export const updateShareImage = (profile_id, blob) => new Promise((resolve) => {
    const data = new FormData();
    data.append('profile_id', profile_id);
    data.append('photo', share.blobToFile(blob));
    data.append('url', __getURL());

    fetch(ENDPOINT + '/upload-wall-photo', {
        method: 'POST',
        body: data,
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.result === "success") {
                resolve({
                    link: response.link,
                });
            } else {
                console.log(response);
                resolve(null);
            }
        })
        .catch((e) => {
            console.error(e);
            resolve(null);
        });
});

export const getVKEmail = () => new Promise((resolve) => {
    bridge.send('VKWebAppGetEmail', {})
        .then(({ email }) => resolve(email))
        .then(() => resolve(''));
});

export const createProfile = () => new Promise((resolve) => {
    const data = new FormData();
    data.append('url', __getURL());

    fetch(ENDPOINT + '/create-profile', {
        method: 'POST',
        body: data,
    })
        .then((response) => response.json())
        .then((response) => {
            if (response.result === "success") {
                resolve({
                    profile_id: response.profile_id,
                });
            } else {
                console.log(response);
                resolve(null);
            }
        })
        .catch((e) => {
            console.error(e);
            resolve(null);
        });
});


export const deletePhoto = (profile_id) => new Promise((resolve) => {
    const data = new FormData();
    data.append('url', __getURL());
    data.append('profile_id', profile_id);

    fetch(ENDPOINT + '/delete-photo', {
        method: 'POST',
        body: data,
    })
        .then((response) => response.json())
        .then((response) => {
            resolve(null);
        })
        .catch((e) => {
            console.error(e);
            resolve(null);
        });
});
