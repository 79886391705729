import 'core-js/features/map';
import 'core-js/features/set';

import React from 'react';
import ReactDOM from 'react-dom';
import bridge from '@vkontakte/vk-bridge';

import GA from 'react-ga';
import App from './containers/App';
import '@vkontakte/vkui/dist/vkui.css';

import { utils } from '@mini-core/core';

window.vk_settings = utils.parseParams(window.location.search);
GA.initialize('UA-165001965-2', {
    titleCase: true,
    gaOptions: {
        userId: Date.now() + (window.vk_settings.vk_user_id || 0),
    },
});

bridge.send('VKWebAppInit');
bridge.subscribe(utils.schemeChanger);

ReactDOM.render(<App />, document.getElementById('root'));
